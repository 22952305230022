<template>
  <section class="app-footer">
    <footer class="footer">
        
    </footer>
   
  </section>
</template>

<script>
export default {
  name: 'app-footer',
  mounted() {
      
  },
}
</script>

<style scoped>
     
</style>