<template>
  <section class="main-view">

    <div class="await_loader_outer">
      <div class='await_loader'>
        <b-spinner type="grow" label="Loading..."></b-spinner>
        <b-spinner type="grow" label="Loading..." class="ms-3"></b-spinner>
        <b-spinner type="grow" label="Loading..." class="ms-3"></b-spinner>
      </div>
    </div>

    <div class="container-scroller">

      <div class="w-100 page-body-wrapper">
        <!-- container-fluid -->

        <div class="main-panel h-100">
          <div class="content-wrapper custom-content-wrapper">
            <Sidebar/>
            <section class="home-section">
              <Header/>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </section>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  import Header from "./partials/Header";
  import Footer from "./partials/Footer";
  import Sidebar from "./partials/Sidebar";
  export default {
    name: "layout",
    components: {
      Header,
      Sidebar,
      Footer
    },
    
  }
</script>

<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .3s ease;
}

.fade-leave-active {
  transition: opacity .3s ease;
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.container-scroller{
  display: flex;
  overflow: hidden;
}

.main-panel {
  display: grid;
  align-content: center;
}
</style>