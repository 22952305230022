import Vue from "vue"
import Router from "vue-router"

import layout from '@/layout'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0, x: 0 }),
  mode: 'history',
  routes: [

    {
      path: "/",
      name: "Landing_Page",
      component: () => import('@/upages/common/landing')
    },

    {
        path: "/start_now",
        name: "start_now",
        component: () => import('@/upages/auth/start_now')
    },

    {
      path: "/signup",
      name: "SignUp",
      component: () => import('@/upages/auth/signup')
    },

    {
      path: "/login",
      name: "Login",
      component: () => import('@/upages/auth/login')
    },

    {
      path: "/teachers",
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: "signup",
          name: "Teacher_Signup",
          component: () => import('@/upages/auth/teacher_singup')
        }
      ]
    },

    {
      path: "/parents",
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        // {
        //   path: "signup",
        //   name: "Parent_Signup",
        //   component: () => import('@/upages/auth/parent_singup')
        // },
        {
          path: "child_connect",
          name: "Child_Connect",
          component: () => import('@/upages/parent/child_connect')
        }
      ]
    },

    {
      path: "/game",
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        // {
        //   path: "login",
        //   name: "Child_Login",
        //   component: () => import('@/upages/auth/child_login')
        // },
        // {
        //   path: "signup",
        //   name: "Child_Signup",
        //   component: () => import('@/upages/auth/child_singup')
        // },
        {
          path: "setup",
          name: "Child_Setup",
          component: () => import('@/upages/child/setup')
        }
      ]
    },

    {
      path: "/dashboard",
      component: layout,
      children: [
        {
          path: "/",
          name: "Dashboard",
          component: () => import('@/upages/common/dashboard')
        },
      ]
    },

    {
      path: "/help",
      component: layout,
      children: [
        {
          path: "/",
          name: "Help",
          component: () => import('@/upages/common/help')
        },
      ]
    },

    {
      path: "/profile",
      component: layout,
      children: [
        {
          path: "/",
          name: "Profile",
          component: () => import('@/upages/common/profile')
        },
      ]
    },

    {
      path: "/child-settings",
      component: layout,
      children: [
        {
          path: "/",
          name: "Manage_Child",
          component: () => import('@/upages/parent/manage_child')
        },
      ]
    },




    // {
    //   path: '*',
    //   redirect: '/login',
    //   component: () => import('@/upages/auth/login')
    // }


    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/upages/common/404')
        }
      ]
    }
    
  ]
})