import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCookies from "vue-cookies";
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';

import JQuery from 'jquery'
window.$ = JQuery;

Vue.use(VueCookies)
Vue.use(BootstrapVue)

Vue.config.productionTip = true
axios.defaults.withCredentials = true



let config_data = {
  node_server : 'https://sso-backend.eo-space.com:5000',
  game_frontend: "https://game.eo-space.com",

  google_register_login: "https://accounts.google.com/o/oauth2/auth?redirect_uri=http%3A%2F%2Flocalhost:4438%2Flanding%2Fgglogin&response_type=token&client_id=963139208019-60n5g8g094h7vp98gbo79bhi004df1pk.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile",
  password_for_decrypt: "freH4bg4t2",
};

new Vue({
  router,
  render: h => h(App),
  config_data,

  mounted() {
    if (process.env.NODE_ENV === 'development') {
      config_data['node_server'] = "http://localhost:5000";
    }
     if(window.location.href.substr(0,5) !== 'https'){
       window.location.href = window.location.href.replace('http:', 'https:');
     }
  },
  methods: {


    // AUTH
    // AUTH
    // AUTH
    userLogin: function(email, password){
      let data = {
        email,
        password
      }
      axios.post(config_data.node_server + "/auth/login", data)
        .then((response) => {
          console.log(response)

          let adult = response.data.user;

          let user_data = this.encrypt_json(adult);
          localStorage.setItem('user', user_data);


          if(adult.childs.length > 0){
            let selected_child = adult.childs[0];
            
            let selected_child_data = this.encrypt_json(selected_child);
            localStorage.setItem('selected_child', selected_child_data);

            router.push("/dashboard")
          } else {
            router.push("/parents/child_connect")
          }

          

          
        })
        .catch((errors) => {
            console.log(errors)
            console.log("Cannot log in")
        })
    },

    getUserData: function (rldata) {
        $('.await_loader_outer').show();

        let user_data = localStorage.getItem('user');
        let user_data_decrypted = this.decrypt_json(user_data);

        // let selected_child_data = localStorage.getItem('selected_child');
        // let selected_child_data_decrypted = this.decrypt_json(selected_child_data);

        if(user_data_decrypted){

            if(user_data_decrypted.childs.length == 0){
                if (this.$route.path !== '/parents/child_connect') window.location.href="/parents/child_connect";
            }
                console.log("getUserData||||||||||||||||||");
                console.log(user_data_decrypted);
                console.log("getUserData||||||||||||||||||");

            let current_user_gr = '';
            if(user_data_decrypted.gr == 'GR_PARENT'){
                current_user_gr = 'Parent';
            }
            else if(user_data_decrypted.gr == 'GR_TEACHER'){
                current_user_gr = 'Teacher';
            }
            user_data_decrypted.role = current_user_gr;
            rldata.user = user_data_decrypted;
        } else {
            axios.get(config_data.node_server + "/auth/users", { withCredentials: true })
            .then((response) => {
                if(response.data.user != null){

                    if(response.data.user.childs.length == 0){
                        if (this.$route.path !== '/parents/child_connect') window.location.href="/parents/child_connect";
                    }

                    console.log('dash')
                    console.log(response)
                    console.log('dash')

                    let current_user_gr = '';
                    if(response.data.user.gr == 'GR_PARENT'){
                        current_user_gr = 'Parent';
                    }
                    else if(user_data_decrypted.gr == 'GR_TEACHER'){
                        current_user_gr = 'Teacher';
                    }


                    let user_data = this.encrypt_json(response.data.user);
                    localStorage.setItem('user', user_data);


                    response.data.user.role = current_user_gr;
                    rldata.user = response.data.user; 
                }
                else {
                    this.logout();
                    // router.push("/login");
                }
            })
            .catch((errors) => {
                console.log(errors)
                this.logout();
                // router.push("/")
            })
        }

        $('.await_loader_outer').hide();
    },


    getSelectedChildData: function (rldata) {
        $('.await_loader_outer').show();

        let selected_child_data = localStorage.getItem('selected_child');

        if(selected_child_data){
            let selected_child_data_decrypted = this.decrypt_json(selected_child_data);
            rldata.selected_child = selected_child_data_decrypted;
        } else {
            let user_data = localStorage.getItem('user');
            let user_data_decrypted = this.decrypt_json(user_data);

            
                console.log("getSelectedChildData||||||||||||||||||");
                console.log(user_data_decrypted);
                console.log("getSelectedChildData||||||||||||||||||");

            if(user_data_decrypted.childs.length > 0){
                let selected_child = user_data_decrypted.childs[0];
                
                let selected_child_data = this.encrypt_json(selected_child);
                localStorage.setItem('selected_child', selected_child_data);

                rldata.selected_child = selected_child;
            }
        }

        $('.await_loader_outer').hide();
    },


    logout: function () {
      axios.get(config_data.node_server + "/auth/logout")
      .then(() => {
        localStorage.removeItem('selected_child');
        localStorage.removeItem('child_setup');
        localStorage.removeItem('user');

        if (this.$route.path !== '/login') router.push("/login");
        
      })
    },


    login_social: function(event) {
      localStorage.setItem('social_operation', 'login');

      let button = event.target.getAttribute('data-uloginbutton');
      console.log(button);
      switch(button){
        case "Google":
          console.log('Google');
          // console.log(event.target)
          window.location.href = `${config_data.google_register_login}`;
          break;

        default:
        console.log('none');
        // console.log(event.target)
        window.location.reload();
      }
    },

    adults_server_signup: function(data) {
      axios.post(config_data['node_server'] + "/auth/registration", data)
      .then((response) => {
        this.userLogin(data.email, data.password);
        // router.push("/dashboard");
      })
      .catch((errors) => {
        console.log(errors)
        console.log("Cannot register")
      })
    },
    
    // AUTH
    // AUTH
    // AUTH


    // CHILD OPERATIONS
    // CHILD OPERATIONS

    child__check_login_availability: function(data, rldata){
        if(data.adult_id != ''){
          axios.post(config_data['node_server'] + "/child/check_login_availability", data)
            .then((response) => {
                
                console.log(response.data.username)
                rldata.rand_child_data.username = response.data.username;
              
            })
            .catch((errors) => {
              console.log(errors);
              errors.response.data.errors.errors.forEach(err => {
                  alert(err.msg);
              });
            })
        } else {
          window.location.reload();
        }
    },

    add_new_child__rand_data: function(data){
        if(data.adult_id != ''){
          axios.post(config_data['node_server'] + "/child/registration", data)
            .then((response) => {

                  let child_login_data = {
                    username: response.data.child.username,
                    password: response.data.password
                  };

                  axios.post(config_data.node_server + "/child/login", child_login_data)
                    .then((response_login) => {
                        console.log(response_login)
                        let link = config_data['game_frontend'] + "/?_id=" + response_login.data.child._id;
                        window.location.href = link;
                    })
                    .catch((errors_login) => {
                        console.log(errors_login)
                        console.log("Cannot log in")
                        alert('Cannot log in');
                    })
              
            })
            .catch((errors) => {
              console.log(errors);
              errors.response.data.errors.errors.forEach(err => {
                  alert(err.msg);
              });

              window.location.reload();
            })
        } else {
          window.location.reload();
        }
      },

    add_new_child: function(data){
      if(data.adult_id != ''){
        axios.post(config_data['node_server'] + "/child/registration", data)
          .then((response) => {

            if(response.data.adult_connected){
                let user_data = this.encrypt_json(response.data.user);
                localStorage.setItem('user', user_data);

                console.log("add_new_child||||||||||||||||||");
                console.log(response.data.user);
                console.log("add_new_child||||||||||||||||||");

                router.push("/dashboard")
            } else {
                let child_obj = response.data.child;
                child_obj.raw_password = response.data.password;

                let child_data = this.encrypt_json(child_obj);
                localStorage.setItem('child_setup', child_data);
                router.push("/game/setup");
            }
            
          })
          .catch((errors) => {
            console.log(errors);
            errors.response.data.errors.errors.forEach(err => {
                alert(err.msg);
            });
          })
      } else {
        window.location.reload();
      }
    },

    childLogin: function(username, password){
      let data = {
        username,
        password
      };
      axios.post(config_data.node_server + "/child/login", data)
        .then((response) => {
          console.log(response)
          let link = config_data['game_frontend'] + "/?_id=" + response.data.child._id;
          window.location.href = link;
        })
        .catch((errors) => {
          console.log(errors)
          console.log("Cannot log in")
          alert('Cannot log in');
        })
    },

    go_to_childs_account: function(){
        let selected_child_data = localStorage.getItem('selected_child');

        if(selected_child_data){
            let selected_child_data_decrypted = this.decrypt_json(selected_child_data);

            let link = config_data['game_frontend'] + "/?_id=" + selected_child_data_decrypted._id;
            window.open(link, '_blank').focus();
        }
    },

    child_logout: function () {
        localStorage.removeItem('child_setup');
        router.push("/login");
        // router.push("/game/login");
    },

    getChildDataLS: function (rldata) {
      let child_data = localStorage.getItem('child_setup');
      let child_data_decrypted = this.decrypt_json(child_data);
      
      rldata.child_data = child_data_decrypted;
    },

    child_connect: function(data){
        if(data.adult_id != ''){
            axios.post(config_data.node_server + "/child/parent_connect", data)
                .then((response) => {
                    console.log(response)
                    let user_data = this.encrypt_json(response.data.adult);
                    localStorage.setItem('user', user_data);
                    // adult
                    router.push("/dashboard")
                })
                .catch((errors) => {
                    alert(errors.response.data.message);
                    console.log(errors)
                    // console.log("Cannot log in")
                    // alert('Cannot log in');
                })
        } else {
            window.location.reload();
        }
    },


    change__selected_child: function (selected_child_index) {
        $('.await_loader_outer').show();

        let user_data = localStorage.getItem('user');
        let user_data_decrypted = this.decrypt_json(user_data);

        if(user_data_decrypted.childs.length > 0 && user_data_decrypted.childs[selected_child_index]){
            let selected_child = user_data_decrypted.childs[selected_child_index];
            
            let selected_child_data = this.encrypt_json(selected_child);
            localStorage.setItem('selected_child', selected_child_data);

            window.location.reload();
        } else {
            window.location.reload();
        }

        $('.await_loader_outer').hide();
    },

    update__child_grade: function(data){
        axios.post(config_data.node_server + "/child/update/child_grade", data)
            .then((response) => {
                let child_data = this.encrypt_json(response.data.child);
                localStorage.setItem('selected_child', child_data);

                alert('Done');

                window.location.reload();
            })
            .catch((errors) => {
                console.log(errors);
                alert('Error, check console');
            })
    },

    update__child_password: function(data){
        axios.post(config_data.node_server + "/child/update/child_password", data)
            .then((response) => {
                
                let child_data = this.encrypt_json(response.data.child);
                localStorage.setItem('selected_child', child_data);

                alert('Done');

                window.location.reload();
            })
            .catch((errors) => {
                console.log(errors);
                console.log(errors.response.data.message);
                alert('Error, check console');
                
            })
    },

    // CHILD OPERATIONS
    // CHILD OPERATIONS




    // ADULT OPERATIONS
    // ADULT OPERATIONS


    update__adult_name: function(data){
        axios.post(config_data.node_server + "/adult/update/adult_name", data)
            .then((response) => {
                let user_data = this.encrypt_json(response.data.adult);
                localStorage.setItem('user', user_data);

                alert('Done');

                window.location.reload();
            })
            .catch((errors) => {
                console.log(errors);
                alert('Error, check console');
            })
    },

    update__adult_password: function(data){
        axios.post(config_data.node_server + "/adult/update/adult_password", data)
            .then((response) => {
                
                let user_data = this.encrypt_json(response.data.adult);
                localStorage.setItem('user', user_data);

                alert('Done');

                window.location.reload();
            })
            .catch((errors) => {
                console.log(errors);
                console.log(errors.response.data.message);
                alert('Error, check console');
                
            })
    },


    // ADULT OPERATIONS
    // ADULT OPERATIONS



    encrypt_json: function (json_data) {

      var str = JSON.stringify(json_data);
      var crypto = require('crypto');
      var mykey = crypto.createCipher('aes-128-cbc', config_data['password_for_decrypt']);
      var mystr = mykey.update(str, 'utf8', 'hex')
      mystr += mykey.final('hex');

      return mystr;
    },


    decrypt_json: function (cjson_data) {

        var crypto = require('crypto');
        var mykey = crypto.createDecipher('aes-128-cbc', config_data['password_for_decrypt']);
        var mystr = mykey.update(cjson_data, 'hex', 'utf8')
        mystr += mykey.final('utf8');
        var json = JSON.parse(mystr);

        return json;
    },
  }
}).$mount('#app')

