<template>
<div v-if="rldata.flags.user_loaded">
  <b-navbar toggleable="lg" class="px-5 header__nav">
    <b-navbar-brand class="noselect d-flex align-items-center" style="cursor: default;">
        <!-- <div style="d-flex align-items-center"> -->
            <img src="/resource/img/logo.svg" alt="logo" style="height: 32px;">
            <p class="ms-2" style="color: #2E3B4B; font-size: 20px; font-weight: 500; margin: 0;">EO Space</p>
        <!-- </div> -->
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- <b-navbar-nav style="width: 50%; display:flex; justify-content: space-around;">

        <b-nav-item-dropdown right v-if="rldata.flags.show_children_select">
          <template #button-content>
            <span><img width="25" height="25" src="../../assets/images/user_default.svg" alt="profileImg"> {{rldata.selected_child.username}}</span>
          </template>
          <b-dropdown-item v-for="(child, index) in rldata.user.childs" :key="child._id" v-bind:class="{ 'd-none': child._id == rldata.selected_child._id }" @click="change_selected_child" :data-childID=index><img width="25" height="25" src="../../assets/images/user_default.svg" alt="profileImg"> {{child.username}}</b-dropdown-item>
          <b-dropdown-item><router-link :to="{ name: 'Child_Connect'}" style="color: inherit; font-size:inherit; text-decoration:none;">Add Child</router-link></b-dropdown-item>
        </b-nav-item-dropdown>
        
        <b-nav-item><b>Math</b></b-nav-item>
        <b-nav-item><b>Soft skills</b></b-nav-item>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ms-auto profile_nav">
        <b-nav-item-dropdown right v-if="rldata.flags.show_children_select">
          <template #button-content>
            <!-- <img width="25" height="25" src="../../assets/images/user_default.svg" alt="profileImg">  -->
            <span>{{rldata.selected_child.first_name}} {{rldata.selected_child.initial}}.</span>
          </template>
          <b-dropdown-item v-for="(child, index) in rldata.user.childs" :key="child._id" v-bind:class="{ 'd-none': child._id == rldata.selected_child._id }" @click="change_selected_child" :data-childID=index><img width="25" height="25" src="../../assets/images/user_default.svg" alt="profileImg"> {{child.username}}</b-dropdown-item>
          <b-dropdown-item><router-link :to="{ name: 'Child_Connect'}" style="color: inherit; font-size:inherit; text-decoration:none;">Add Child</router-link></b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content class=" d-flex align-items-center">
            <!-- <div class="ms-3 d-flex align-items-center"> -->
                <!-- <img width="25" height="25" src="../../assets/images/user_default.svg" alt="profileImg"></img> -->
                <span class="ms-3">{{rldata.user.full_name}}</span>
            <!-- </div> -->
          </template>
          <b-dropdown-item :to="{ name: 'Profile'}"><i class='mdi mdi-cog'></i> Settings</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'Manage_Child'}"><i class='mdi mdi-teddy-bear'></i> Manage child</b-dropdown-item>
          <!-- <b-dropdown-item :to="{ name: 'Help'}"><i class='mdi mdi-chat-question'></i> Help</b-dropdown-item> -->
          <b-dropdown-item v-on:click="$root.logout()"><i class='mdi mdi-exit-to-app'></i> Log out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
  var data = {
    user: null,
    // user: {
    //   full_name: '',
    //   email: '',
    //   password: '',
    //   childs: [],
    // },

    selected_child: null,

    flags: {
      show_children_select: false,
      user_loaded: false,
    }
  };
 
  export default {
    name: "app-header",
    data () {
        return {
            rldata: data
        }
    },
    methods: {
      change_selected_child: function(event){
        let new_selected_child__index = event.target.getAttribute('data-childID');
        this.$root.change__selected_child(new_selected_child__index);
      }
    },
    created () {
      try{
        this.$root.getUserData(this.rldata);

        
      } catch(err){
        console.log(err);
        // this.$root.logout();
      }
    },
    mounted(){
    },
    watch: {
        "rldata.user": function(handler){
            if(handler != null){
                this.rldata.flags.user_loaded = true;

                this.$root.getSelectedChildData(this.rldata);
            }
        },


        "rldata.selected_child": function(handler){
            if(handler != null) {
            //   if(this.rldata.user.childs.length > 0){
                // this.rldata.selected_child = this.rldata.user.childs[0];
                this.rldata.flags.show_children_select = true;
            //   }
            }
        }
    }
  }
</script>


