<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'app'
  }
</script>

<style lang="scss">
@import "@/assets/scss/normalize";
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "@/assets/scss/style";

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .5s ease;
}

.fade-leave-active {
  transition: opacity .5s ease;
  opacity: 0;
}
</style>