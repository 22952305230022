<template>
<!-- <section class="app"> -->
  <div class="sidebar">
    <div class="logo-details">
      <!-- <i class='mdi mdi-crosshairs-question icon'></i>
        <div class="logo_name">EO</div> -->
        <i class='mdi mdi-menu d-none d-sm-block' id="btn" ></i>
    </div>
    <ul class="nav-list">
      <!-- <li class="d-none d-sm-block">
          <i class='mdi mdi-magnify' ></i>
         <input type="text" placeholder="Поиск...">
         <span class="tooltip">Поиск</span>
      </li> -->

      <li>
          <router-link :to="{ name: 'Dashboard'}">
            <i class='mdi mdi-home'></i>
            <span class="links_name">Dashboard</span>
          </router-link>
         <span class="tooltip">Dashboard</span>
      </li>

      <li>
          <router-link :to="{ name: 'Login'}">
            <i class='mdi mdi-chart-bar'></i>
            <span class="links_name">Event progress</span>
          </router-link>
         <span class="tooltip">Event progress</span>
      </li>

      <li>
          <router-link :to="{ name: 'Login'}">
            <i class='mdi mdi-chart-areaspline-variant'></i>
            <span class="links_name">Week progress</span>
          </router-link>
         <span class="tooltip">Week progress</span>
      </li>

      <li>
          <router-link :to="{ name: 'Login'}">
            <i class='mdi mdi-book-open-blank-variant'></i>
            <span class="links_name">Curriculum</span>
          </router-link>
         <span class="tooltip">Curriculum</span>
      </li>

      <!-- <li>
          <router-link :to="{ name: 'Login'}">
            <i class='mdi mdi-atlassian'></i>
            <span class="links_name">Recommendation</span>
          </router-link>
         <span class="tooltip">Recommendation</span>
      </li> -->


     <li class="profile d-flex justify-content-center align-items-center" v-on:click="$root.logout()" style="cursor: pointer;">
         <!-- <div class="profile-details">
           <img src="../../assets/images/user_default.svg" alt="profileImg">
           <div class="name_job">
             <div class="name">{{this.rldata.user.full_name}}</div>
             <div class="job">{{this.rldata.user.role}}</div>
           </div>
         </div> -->
            <p class="m-0">Log out</p>
            <i class='mdi mdi-exit-to-app' id="log_out"></i>
     </li>
    </ul>
  </div>
<!-- </section> -->
</template>

<script>
  var data = {
    user: {
      full_name: '',
      email: '',
      password: '',
    },
  };
 
  export default {
    name: "app-sidebar",
    data () {
    return {
      rldata: data
    }
  },
    methods: {
      
    },
    created () {
      try{
        this.$root.getUserData(this.rldata);
      } catch(err){
        // console.log(err);
        this.$root.logout();
      }
    },
    mounted(){     
        let sidebar = document.querySelector(".sidebar");
        let closeBtn = document.querySelector("#btn");
        let searchBtn = document.querySelector(".mdi-magnify"); 

        let logo_name = $('.logo_name');

        closeBtn.addEventListener("click", ()=>{
            sidebar.classList.toggle("open");
            menuBtnChange();
        });

        // searchBtn.addEventListener("click", ()=>{ 
        //     sidebar.classList.toggle("open");
        //     menuBtnChange();
        // });

        function menuBtnChange() {
            if(sidebar.classList.contains("open")){
                closeBtn.classList.replace("mdi-menu", "mdi-backburger");
                logo_name.show();
            }else {
                closeBtn.classList.replace("mdi-backburger","mdi-menu");
                logo_name.hide();
            }
        }
    }
  }
</script>


